import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import Zoom from "react-medium-image-zoom"
import firebase from "gatsby-plugin-firebase"
import { Flex, Box } from "@chakra-ui/core"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1, H3 } from "../components/Typography"
import { BannerAd } from "../components/Ad"
import ShareButtons from "../components/ShareButtons"
import TweetEmbed from 'react-tweet-embed'
import Parser from 'html-react-parser';
import { Facebook } from 'reactjs-social-embed'
import InstagramEmbed from 'react-instagram-embed';

const settings = {
  dots: true,
  lazyLoad: "ondemand",
  adaptiveHeight: true,
}

const wrapperStyle = {
  width: "100%",
  height: "100%",
  margin: "auto",
}

function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min) + min)
}

function getTwitterId(url) {
  return url?.replace(/\/$/, "").split("/").slice(-1)[0].split("?")[0]
}

function getYoutubeId(url) {
  if (!url) return '';
  const yID = url.split('/').splice("-1")[0];
  if (!yID) return '';
  const splitId = yID.split('&')[0]
  if (!splitId) return '';
  const finalID = splitId.split("v=").splice("-1")[0];
  return finalID || '';
}


function getTweetEmbed(url) {
  const fetchurl = `https://publish.twitter.com/oembed?url=${encodeURIComponent(url)}`;
  console.log('fetchurl ---> ', fetchurl)
  return fetch(fetchurl, { mode: 'cors' })
    .then(response => {
      return response.json();
    })
    .then((json) => {
      console.log('json:: ', json)
      return json.html;
    })
    .catch((error) => {
      console.error(error);
    });
}

function YoutubeEmbed({ youtubeId }) {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
      />
    </div>
  );
};

function Tweet({ url }) {
  console.log('works 2-', getTwitterId(url))
  return <TweetEmbed id={getTwitterId(url)} />;
}

export default function Template({ data }) {
  const { article, allAd } = data
  const ads =
    allAd?.edges.map(edge => edge.node).filter(ad => ad.type === "banner") || []

  const bannerAdBottom = ads[getRandom(0, ads.length)]
  const bannerAdTop = ads[getRandom(0, ads.length)]
  const {
    title,
    description,
    featuredImage,
    otherImagesLinks,
    fields,
    summary,
  } = article || {}
  const { timeToRead, date, slug } = fields || {}
  useEffect(() => {
    firebase.analytics().setCurrentScreen(title)
  }, [])

  return (
    <Layout>
      <SEO
        title={title}
        description={summary}
        image={featuredImage?.childImageSharp?.resize}
      />
      <Flex marginBottom={8} align="center" justify="center" textAlign="center">
        <H1>{title}</H1>
      </Flex>
      <Box mb={8}>
        <Slider {...settings}>
          <Zoom>
            <Flex
              align="center"
              justify="center"
              flexGrow="1"
              width="90vw"
              maxW="1120px"
            >
              <Img
                fluid={featuredImage?.childImageSharp?.fluid}
                alt="featuredImage"
                style={wrapperStyle}
              />
            </Flex>
          </Zoom>
          {otherImagesLinks?.length > 0 &&
            otherImagesLinks.map((imgLink, index) => (
              <Flex
                key={`${index}-other-image`}
                width={["100vw", "80vw"]}
                align="center"
                justify="center"
                style={{ display: "flex !important" }}
              >
                <Zoom>
                  <img src={imgLink} style={{ maxHeight: "500px" }} />
                </Zoom>
              </Flex>
            ))}
        </Slider>
      </Box>
      <br />
      <H3>
        {date} &nbsp; &bull; &nbsp; {timeToRead}
      </H3>
      {bannerAdTop && (
        <Box mt={3} marginX="auto">
          <BannerAd ad={bannerAdTop} />
        </Box>
      )}
      <div
        className="blog-post-content"
      >
        {Parser(description, {
          replace: (domNode) => {
            if (domNode.name === 'tweet') {
              console.log('yes -> ', domNode.attribs.url)
              return (<Tweet url={domNode.attribs.url} />);
            }
            if (domNode.name === 'youtube') {
              return <YoutubeEmbed youtubeId={getYoutubeId(domNode.attribs.url)} />;
            }
          }
        })}
      </div>
      <Box mt={3} />
      <ShareButtons
        title={title}
        url={`https://www.laudiumtoday.co.za/${slug}`}
        twitterHandle={"LaudiumToday"}
        tags={["LaudiumToday", "news"]}
      />
      <Box mt={3} />
      {bannerAdBottom && (
        <Box mt={3} marginX="auto">
          <BannerAd ad={bannerAdBottom} />
        </Box>
      )}
      <Box mt={3} />
    </Layout>
  )
}


export const pageQuery = graphql`
  query($id: String!) {
    article(id: { eq: $id }) {
      articleCategories {
        name
      }
      description
      summary
      featuredImage {
        childImageSharp {
          resize(width: 1200) {
            height
            src
            width
          }
          fluid(maxHeight: 750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      id
      fields {
        timeToRead
        date(formatString: "DD MMMM YYYY")
        slug
      }
      otherImagesLinks
      title
    }
    allAd {
      edges {
        node {
          customer
          id
          type
          imageUrl
          website
        }
      }
    }
  }
`
