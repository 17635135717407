import React from "react"
import { Flex, Image } from "@chakra-ui/core"
import useWindowSize from "../../hooks/useWindowSize"
import { maxMobileSize } from "../../constants"

function BannerAd({ ad }) {
  const windowSize = useWindowSize()
  const isDesktop = windowSize.width > maxMobileSize
  if (!ad) return null
  return (
    <a href={ad.website} target="_blank">
      <Flex
        width={isDesktop ? "728px" : "320px"}
        height={isDesktop ? "90px" : "50px"}
        marginY={4}
        marginRight={isDesktop ? 0 : "auto"}
        marginLeft="auto"
        justify="center"
        align="center"
      >
        <Image
          objectFit="cover"
          htmlWidth="100%"
          htmlHeight="100%"
          src={ad.imageUrl}
          alt={`${ad.customer} banner ad`}
        />
      </Flex>
    </a>
  )
}

function SquareAd({ ad }) {
  if (!ad) return null
  return (
    <a href={ad.website} target="_blank">
      <Flex
        width="300px"
        height="250px"
        marginY={1}
        marginX="auto"
        justify="center"
        align="center"
      >
        <Image
          objectFit="cover"
          htmlWidth="100%"
          htmlHeight="100%"
          src={ad.imageUrl}
          alt={`${ad.customer} banner ad`}
        />
      </Flex>
    </a>
  )
}

export { BannerAd, SquareAd }
